@use "./../../scss/" as *;

.footer {
  position: relative;
  padding: 6rem 2rem;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  &__content {
    @include flex(center, space-between);
    @include mobile {
      max-width: 1000px;
      display: block;
    }

    &__logo {
      @include flex(center, flex-start);
      margin-bottom: 3rem;

    //   @include mobile {
    //     @include flex(center, center);
    //   }
    }

    &__menus {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 60%;
      @include mobile {
          grid-template-columns: repeat(2, 1fr);
          width: auto;
      }
    }
    &__menu {
      @include flex(flex-start, flex-start);
      flex-direction: column;
      margin-top: 1rem;

      font-size: 1.5rem;
      font-weight: 600;

      & > * ~ * {
        margin-top: 1rem;
      }
    }
  }
}

@use "../../scss/" as *;

.swiper-container {
  overflow: visible;
  @include tablet {
    overflow: hidden;
  }
  @include mobile {
    overflow: hidden;
  }
}
.swiper-slide:first-child {
  &:hover {
    .movie-card {
      transform-origin: left;
    }
  }
}
.movie-card {
  height: 450px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  transition: all 0.2s ease;
  margin: 20px 0px;
  border-radius: $border-radius-small;

  .content {
    @include flex(center, flex-end);
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 450px;
    top: 0;
    left: 0;
    transform: scale(0);
    transition-duration: 0.2s;
    opacity: 0;
    border-radius: 8px;
    &__video {
      width: 100%;
      height: 100%;
    }

    &__info {
      @include flex(center, center);
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 1rem;
      &__detail {
        @include flex(center, space-between);
        gap: 20px;
        border-top: 1px solid #7c7c7c;
        width: 100%;
        margin-top: 1rem;
        padding-top: 1rem;

        &__vote {
          margin: 1rem 0;
        }

        &__go {
          @include flex(center, center);
          height: 35px;
          border: 1px solid rgba(255, 255, 255, 0.404);
          color: rgba(255, 255, 255, 0.404);
          border-radius: 50%;
          width: 35px;
          transition: 0.2s ease;
          & i {
            line-height: 3;
          }
          &:hover {
            border: 1px solid $white;
            color: $white;
            a {
              color: $white;
            }
          }
        }
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.5);
      z-index: 5;
      box-shadow: $box-shadow-card;
      color: $white;
      .content {
        transition-delay: 0.5s;
        transform: scale(1);
        transform-origin: center center;
        opacity: 1;
        &__video {
          transition-delay: 0.5s;
          transform: scale(1);
          transform-origin: center center;
          opacity: 1;
        }
      }
    }
  }

  @include tablet {
    height: 500px;
    margin: 0px;
    .content {
      height: 500px;
    }
  }
  @include mobile {
    height: 250px;
    margin: 0px;
    .content {
      height: 250px;
    }
  }
}

@use './../../scss/' as *;

button{
    cursor:pointer;
    font-family: $font-family;
}

.btn {
    border:4px solid transparent;
    background-color: $main-color;
    color: $white;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    box-shadow:  0 0 15px 8px #F16F234d;
    transition: box-shadow .3s ease;
    position: relative;
}

.btn:hover{
    box-shadow:  0 0 15px 13px #F16F234d;
}

.btn-outline{
    border: 3px solid $white;
    background-color: transparent;
    color:$white;
    box-shadow: unset;
    transition: color .3s ease, background-color .3s ease;
}

.btn-outline:hover{
    box-shadow: unset;
    color:$main-color;
    background-color: $white;
}

.btn.small{
    border-width: 2px;
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
}
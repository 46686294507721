@use './variables' as *;

@mixin mobile {
    @media only screen and (max-width:$mobile-width){
        @content;
    }
}
@mixin tablet {
    @media only screen and (max-width: $table-width){
        @content;
    }
}
$body-bg: #0f0f0f;
$main-color: #F16F23;
$txt-color:#fff;
$white:#fff;
$black:#000;

$box-shadow: 0 7px 29px 0 rgba(100,100,111,0.2);
$box-shadow-card: 0px 0px 55px 0px rgb(0 0 0 / 83%);

$header-height: 8rem;
$header-shrink-height:5rem;
$border-radius: 30px;
$border-radius-small: 8px;
$mobile-width: 600px;
$table-width: 1024px;

$font-family: "Montserrat", sans-serif;